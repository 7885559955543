import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import classNames from 'classnames';

import { LoadingIndicator } from 'components/loadingIndicator';
import {
  FormField,
  FormTextArea,
  SubmitButton,
  SubmitMessageWithIcon,
} from 'components/forms';
import 'components/forms/scss/form.scss';
import { isWhiteSpace } from 'utilities';

const requiredFieldText = 'Field is required.';
const minFieldText = 'Field value is too short.';
const maxFieldText = 'Field value is too long.';
const maxSymbolCount = 5000;
const whiteSpaceFieldText = 'Field must contain letters or numbers.';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min('1', minFieldText)
    .max('100', maxFieldText)
    .required(requiredFieldText)
    .test(
      'check-whitespace',
      whiteSpaceFieldText,
      (value) => !isWhiteSpace(value)
    ),
  email: Yup.string()
    .email('Field value must be a valid email.')
    .required(requiredFieldText),
  message: Yup.string()
    .min('1', minFieldText)
    .max(maxSymbolCount.toString(), maxFieldText)
    .required(requiredFieldText)
    .test(
      'check-whitespace',
      whiteSpaceFieldText,
      (value) => !isWhiteSpace(value)
    ),
});

const ContactAuthorForm = ({ formTitle, formDescription }) => {
  const [currentSymbolCount, setCurrentSymbolCount] = useState(0);
  const [formIsSubmitted, setIsFormSubmitted] = useState(false);
  const [formBeingSubmitted, setFormBeingSubmitted] = useState(false);
  const [hasSubmissionError, setSubmissionError] = useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  };

  const handleSubmit = async (values, actions) => {
    setFormBeingSubmitted(true);
    fetch('', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact-author-from',
        ...values,
      }),
    })
      .then(() => {
        actions.setSubmitting(false);
        actions.setStatus('submitted');
        setIsFormSubmitted(true);
        setFormBeingSubmitted(false);

        if (typeof window !== 'undefined') {
          window.dataLayer.push({ event: 'contact' });
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        setSubmissionError(true);
        setFormBeingSubmitted(false);
      });
  };

  const handleFieldFocus = () => {
    if (hasSubmissionError) {
      setSubmissionError(false);
    }
  };

  return (
    <div
      className={classNames('form-tile', {
        submitted: formIsSubmitted || formBeingSubmitted,
      })}
      id="contact-author-form"
    >
      <div className="form-tile__content" aria-hidden={formIsSubmitted}>
        <h2 className="form-tile__title">{formTitle}</h2>
        <p className="form-tile__description">{formDescription}</p>
        <Formik
          initialValues={{
            name: '',
            email: '',
            message: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form
              name="contact-author-from"
              method="post"
              data-netlify="true"
              className={classNames(
                'form',
                { submitted: formIsSubmitted || formBeingSubmitted },
                { error: hasSubmissionError }
              )}
            >
              {hasSubmissionError && (
                <p className="form__error">Error submitting form</p>
              )}
              <FormField onFocus={handleFieldFocus} label="Name" name="name" />
              <FormField
                onFocus={handleFieldFocus}
                label="Email"
                name="email"
                type="email"
              />
              <FormTextArea
                maxSymbolCount={maxSymbolCount}
                setCurrentSymbolCount={setCurrentSymbolCount}
                onFocus={handleFieldFocus}
                label="Message"
                name="message"
              />
              <div className="form__counter">
                {`${currentSymbolCount} / ${maxSymbolCount}`}
              </div>
              <SubmitButton>Send</SubmitButton>
            </Form>
          )}
        </Formik>
      </div>
      {formBeingSubmitted && <LoadingIndicator />}
      {formIsSubmitted && (
        <>
          <SubmitMessageWithIcon
            title="Thank you!"
            description={`<p>We will contact you shortly.</p>`}
          />
        </>
      )}
    </div>
  );
};

ContactAuthorForm.propTypes = {
  formTitle: PropTypes.string.isRequired,
  formDescription: PropTypes.string.isRequired,
};

export { ContactAuthorForm };
