import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import './scss/hero-banner-info.scss';
import { HeroBannerLayout } from './HeroBannerLayout';

const HeroBannerInfo = ({
  backgroundImage,
  className,
  title,
  description,
  noGradient,
  renderControls,
  renderRightSide,
  textAlignOnMobile,
}) => (
  <HeroBannerLayout
    className={className}
    backgroundImage={backgroundImage}
    noGradient={noGradient}
  >
    <div className="hero-info">
      <div
        className={classNames(
          'hero-info__left-column',
          textAlignOnMobile === 'center'
            ? 'hero-info__left-column--center'
            : null
        )}
      >
        {title && <h1 className="hero-info__title">{title}</h1>}
        {description && (
          <div
            className="hero-info__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {renderControls && (
          <div className="hero-info__controls">{renderControls()}</div>
        )}
      </div>
      {renderRightSide && (
        <div className="hero-info__right-column">{renderRightSide()}</div>
      )}
    </div>
  </HeroBannerLayout>
);

HeroBannerInfo.defaultProps = {
  textAlignOnMobile: 'left',
};

HeroBannerInfo.propTypes = {
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  noGradient: PropTypes.bool,
  textAlignOnMobile: PropTypes.PropTypes.oneOf(['center', 'left']),
  renderControls: PropTypes.func,
  renderRightSide: PropTypes.func,
};

export { HeroBannerInfo };
