import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Image } from 'components/conditionalImage';

const SectionImage = ({ image, imageText, imageSide }) => (
  <figure
    className={classNames(
      'section__figure',
      imageSide && `section__figure--${imageSide}`
    )}
  >
    <Image imageText={imageText} image={image} className="section__image" />
  </figure>
);

SectionImage.propTypes = {
  imageText: PropTypes.string.isRequired,
  imageSide: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
};

export { SectionImage };
