import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { SectionImage } from './SectionImage';
import './scss/section.scss';

const SectionWithImage = ({
  title,
  description,
  image,
  imageText,
  imageSide,
  className,
}) => {
  return (
    <section className={classNames('section', className && `${className}`)}>
      <div className="section__content">
        {imageSide === 'left' && (
          <SectionImage
            imageSide={imageSide}
            image={image}
            imageText={imageText}
          />
        )}
        <div className="section__text-wrapper">
          <h2 className="section__title">{title}</h2>
          <p className="section__description">{description}</p>
        </div>
        {imageSide === 'right' && (
          <SectionImage
            imageSide={imageSide}
            image={image}
            imageText={imageText}
          />
        )}
      </div>
    </section>
  );
};

SectionWithImage.defaultProps = {
  imageSide: 'right',
};

SectionWithImage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
  imageSide: PropTypes.PropTypes.oneOf(['left', 'right']),
  imageText: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
};

export { SectionWithImage };
