import React from 'react';
import PropTypes from 'prop-types';

import { Course } from './Course';

const Courses = ({ upcomingAcademies, title }) => (
  <div className="students-landing__courses">
    <div className="students-landing__content-wrapper">
      <h2 className="students-landing__courses-title">{title}</h2>
      {upcomingAcademies.map((upcomingAcademy) => (
        <Course upcomingAcademy={upcomingAcademy} key={upcomingAcademy.id} />
      ))}
    </div>
  </div>
);

Courses.propTypes = {
  title: PropTypes.string.isRequired,
  upcomingAcademies: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        shortDescription: PropTypes.string.isRequired,
        info: PropTypes.shape({
          type: PropTypes.string.isRequired,
        }),
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    })
  ),
};

export { Courses };
