import DefaultLogo from 'images/default-images/media-link-placeholder.svg';
import DelfiLogo from 'images/delfi-logo.png';
import DevbridgeLogo from 'images/devbridge-logo.svg';
import LrytasLogo from 'images/lrytas-logo.png';
import LrtLogo from 'images/lrt-logo.png';
import Min15Logo from 'images/15min-logo.png';
import VziniosLogo from 'images/vzinios-logo.png';

const getMediaLinkLogo = (mediaLinkType) => {
  switch (mediaLinkType) {
    case 'Delfi':
      return DelfiLogo;
    case 'Devbridge':
      return DevbridgeLogo;
    case 'Lrytas':
      return LrytasLogo;
    case 'Lrt':
      return LrtLogo;
    case 'Verslo žinios':
      return VziniosLogo;
    case '15min':
      return Min15Logo;
    default:
      return DefaultLogo;
  }
};

export { getMediaLinkLogo };
