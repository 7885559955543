import React from 'react';
import PropTypes from 'prop-types';

import { CMSLink } from 'components/cmsLink';
import generateUniqueId from 'utilities/generateUniqueId';
import { getMediaLinkLogo } from './getMediaLinkLogo';
import './scss/media-links.scss';

const MediaLinks = ({ links, className, title }) =>
  links &&
  links.length > 0 && (
    <div
      className={`media-links ${
        typeof className === 'undefined' ? '' : className
      }`}
    >
      <div className="page__frame">
        {title && <h2 className="media-links__title">{title}</h2>}
        <div className="media-links__row">
          {links.map((link) => (
            <div className="media-links__column" key={generateUniqueId()}>
              {getMediaLinkLogo(link.source) && (
                <figure className="media-links__icon">
                  <img
                    src={getMediaLinkLogo(link.source)}
                    alt={
                      link.source === 'Other'
                        ? 'Media logo placeholder.'
                        : `${link.source} logo.`
                    }
                  />
                </figure>
              )}
              <CMSLink
                className={
                  getMediaLinkLogo(link.source)
                    ? 'media-links__link'
                    : 'media-links__link media-links__link--no-icon'
                }
                linkUrl={link.url}
              >
                {link.name}
              </CMSLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

MediaLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
  className: PropTypes.string,
};

export { MediaLinks };
