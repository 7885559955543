import React from 'react';
import PropTypes from 'prop-types';

const AcademyAddress = ({ location }) => (
  <div className="academy-contacts-row__address" key={location.postalCode}>
    <h3 className="academy-contacts-row__title">{location.city}</h3>
    <p className="academy-contacts-row__info">
      {location.address}
      <br />
      {`${location.city}, ${location.postalCode}`}
      <br />
      {location.country}
    </p>
  </div>
);

AcademyAddress.propTypes = {
  location: PropTypes.shape({
    postalCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
  }),
};

export { AcademyAddress };
