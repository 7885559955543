import React from 'react';
import PropTypes from 'prop-types';

import './scss/academy-trademark.scss';
import CognizantLogo from 'icons/cognizant-softvision-logo.svg';

const AcademyTrademark = ({ trademark }) => (
  <div className="academy-trademark">
    <p className="academy-trademark__text">{trademark}</p>
    <a
      className="academy-trademark__link"
      href="https://www.devbridge.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CognizantLogo />
    </a>
  </div>
);

AcademyTrademark.propTypes = {
  trademark: PropTypes.string.isRequired,
};

export { AcademyTrademark };
