import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Image } from 'components/conditionalImage';
import './scss/hero-banner-image.scss';
import { HeroBannerLayout } from './HeroBannerLayout';
import { ContactAuthorForm } from '../../templates/contactAuthor/components';

const HeroBannerImage = ({
  backgroundImage,
  className,
  title,
  description,
  noGradient,
  renderControls,
  heroImage,
  heroImageText,
  textAlignOnMobile,
  displayContactsForm,
}) => (
  <HeroBannerLayout
    className={className}
    backgroundImage={backgroundImage}
    noGradient={noGradient}
  >
    <div className="hero-image">
      <div
        className={classNames(
          'hero-image__text-content',
          textAlignOnMobile === 'center'
            ? 'hero-image__text-content--center'
            : null
        )}
      >
        {title && <h1 className="hero-image__title">{title}</h1>}
        {description && (
          <p className="hero-image__description">{description}</p>
        )}
        {renderControls && (
          <div className="hero-image__controls">{renderControls()}</div>
        )}
      </div>
      <figure className="hero-image__figure">
        {displayContactsForm ? (
          <div className="contact-author__form-wrapper">
            <ContactAuthorForm />
          </div>
        ) : (
          <Image
            image={heroImage}
            imageText={heroImageText}
            className="hero-image__image"
          />
        )}
      </figure>
    </div>
  </HeroBannerLayout>
);

HeroBannerImage.defaultProps = {
  textAlignOnMobile: 'center',
};

HeroBannerImage.propTypes = {
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  noGradient: PropTypes.bool,
  textAlignOnMobile: PropTypes.PropTypes.oneOf(['center', 'left']),
  renderControls: PropTypes.func,
  heroImage: PropTypes.oneOfType([PropTypes.object]),
  heroImageText: PropTypes.string,
  displayContactsForm: PropTypes.bool,
};

export { HeroBannerImage };
