import React, { useContext } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import './scss/testimonials-slider.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import SliderArrowRight from 'icons/slider-arrow-right.svg';
import SliderArrowLeft from 'icons/slider-arrow-left.svg';
import { Testimonial } from './Testimonial';
import queryTestimonials from './queryTestimonials';

const TestimonialsSlider = ({ testimonialsArray }) => {
  const allTestimonials = queryTestimonials();
  const filteredTestimonials = [];

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  if (testimonialsArray && testimonialsArray.length > 0) {
    testimonialsArray.forEach((testimonial) => {
      const el = allTestimonials.find(
        (e) =>
          e.parent.relativePath ===
          testimonial.replace('src/cms/testimonials/', '')
      );
      if (typeof el !== 'undefined') {
        filteredTestimonials.push(el);
      }
    });
  }

  const NextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      data-role="none"
      type="button"
      className="testimonials-slider__next-arrow"
      aria-label={translation['button.arrow.nextSlide']}
    >
      <SliderArrowRight />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      data-role="none"
      type="button"
      className="testimonials-slider__prev-arrow"
      aria-label={translation['button.arrow.prevSlide']}
    >
      <SliderArrowLeft />
    </button>
  );

  const settings = {
    autoplay: false,
    adaptiveHeight: true,
    dots: true,
    fade: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 350,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          swipeToSlide: true,
          fade: false,
        },
      },
    ],
  };

  return (
    <>
      {filteredTestimonials && filteredTestimonials.length > 0 && (
        <div className="testimonials-slider">
          <div className="testimonials-slider__wrapper">
            <Slider {...settings}>
              {filteredTestimonials &&
                filteredTestimonials.length > 0 &&
                filteredTestimonials.map(({ frontmatter: testimonial, id }) => (
                  <Testimonial
                    testimonialText={
                      testimonial[languageContext.language.id].quote
                    }
                    title={testimonial[languageContext.language.id].title}
                    name={testimonial.name}
                    profileImage={
                      testimonial.avatar && testimonial.avatar.children[0].fixed
                    }
                    key={id}
                  />
                ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
};

TestimonialsSlider.propTypes = {
  testimonialsArray: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
};

export { TestimonialsSlider };
