import React from 'react';
import PropTypes from 'prop-types';

import { generateUniqueId } from 'utilities';
import { AcademyContactsWithAddress } from 'components/academyContacts';
import { AcademyInfoCard } from 'components/academyInfoCard';

const AcademyStudentsFooter = ({
  upcomingAcademies,
  contact,
  locations,
  trademark,
}) => (
  <div className="students-footer">
    <div className="students-footer__wrapper">
      <div className="students-footer__row">
        {upcomingAcademies.map((upcomingAcademy) => (
          <div className="students-footer__column" key={generateUniqueId()}>
            <AcademyInfoCard
              admissionStartDate={
                upcomingAcademy.frontmatter.info.admissionStartDate
              }
              applicationDeadline={
                upcomingAcademy.frontmatter.info.applicationDeadline
              }
              admissionEndDate={
                upcomingAcademy.frontmatter.info.admissionEndDate
              }
              duration={upcomingAcademy.frontmatter.info.duration}
              title={upcomingAcademy.frontmatter.title}
              slug={upcomingAcademy.fields.slug}
              type={upcomingAcademy.frontmatter.info.type}
            />
          </div>
        ))}
      </div>
      <AcademyContactsWithAddress
        email={contact.email}
        locations={locations}
        trademark={trademark}
      />
    </div>
  </div>
);

AcademyStudentsFooter.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      postalCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    })
  ),
  upcomingAcademies: PropTypes.arrayOf(
    PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        shortDescription: PropTypes.string.isRequired,
        info: PropTypes.shape({
          admissionStartDate: PropTypes.string.isRequired,
          admissionEndDate: PropTypes.string,
          applicationDeadline: PropTypes.string,
          duration: PropTypes.string.isRequired,
          type: PropTypes.string.isRequired,
        }),
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    })
  ),
  contact: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
  trademark: PropTypes.string.isRequired,
};

export { AcademyStudentsFooter };
