import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import './scss/academy-contacts-row.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const AcademyContactsRow = ({ email, phone }) => {
  const languageContext = useContext(GlobalStateContext);
  const translations = languageContext.dictionary.translations;

  return (
    <div className="academy-contacts-row">
      <h3 className="academy-contacts-row__title">
        {translations['contacts.title']}
      </h3>
      {email && (
        <p className="academy-contacts-row__info">
          <span className="academy-contacts-row__info-tag">{`${
            translations['contacts.label.email']
          }: `}</span>
          <a
            className="academy-contacts-row__info-content"
            href={`mailto:${email}`}
          >
            {email}
          </a>
        </p>
      )}
      {phone && (
        <p className="academy-contacts-row__info">
          <span className="academy-contacts-row__info-tag">{`${
            translations['contacts.label.phone']
          }: `}</span>
          <a
            className="academy-contacts-row__info-content"
            href={`tel:${phone}`}
          >
            {phone}
          </a>
        </p>
      )}
    </div>
  );
};

AcademyContactsRow.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
};

export { AcademyContactsRow };
