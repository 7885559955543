import { graphql, useStaticQuery } from 'gatsby';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ALL_TESTIMONIALS {
        allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "testimonials" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                name
                category
                lt {
                  title
                  quote
                }
                en {
                  title
                  quote
                }
                pl {
                  title
                  quote
                }
                avatar {
                  children {
                    ... on ImageSharp {
                      fixed(width: 300) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
              }
              parent {
                ... on File {
                  relativePath
                }
              }
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges.map(({ node }) => node);
};
