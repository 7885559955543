import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './scss/hero-banner-layout.scss';

const HeroBannerLayout = ({
  className,
  backgroundImage,
  children,
  bgColor,
  noGradient,
}) => {
  const inlineStyle = {};

  if (backgroundImage) {
    inlineStyle.backgroundImage = `url("${backgroundImage}")`;
  }

  if (bgColor) {
    inlineStyle.backgroundColor = bgColor;
  }

  return (
    <div
      className={classNames(
        'hero-banner',
        className && `${className}`,
        backgroundImage && !noGradient && 'hero-banner--gradient-bg'
      )}
      style={inlineStyle}
    >
      <div className="hero-banner__content-wrapper">{children}</div>
    </div>
  );
};

HeroBannerLayout.defaultProps = {
  className: null,
  backgroundImage: null,
};

HeroBannerLayout.propTypes = {
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  noGradient: PropTypes.bool,
};

export { HeroBannerLayout };
