import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import CircledArrow from 'icons/circled-arrow.svg';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { getFullMonthAndDay } from 'utilities';

const UpcomingAcademies = ({ upcomingAcademies }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <div className="students-promo__upcoming students-upcoming">
      <p className="students-upcoming__wrapper-title">
        {translation['upcomingAcademies.title']}
      </p>
      {upcomingAcademies &&
        upcomingAcademies.length &&
        upcomingAcademies.map((upcomingAcademy) => (
          <Link
            to={upcomingAcademy.fields.slug}
            className="students-upcoming__link"
            key={upcomingAcademy.id}
          >
            <div className="students-upcoming__info">
              <p className="students-upcoming__date">
                {upcomingAcademy.frontmatter.info.startDate &&
                  getFullMonthAndDay(
                    upcomingAcademy.frontmatter.info.startDate,
                    languageContext.language.id
                  )}
              </p>
              <p className="students-upcoming__title">
                {upcomingAcademy.frontmatter.title}
              </p>
            </div>
            <CircledArrow />
          </Link>
        ))}
    </div>
  );
};

UpcomingAcademies.propTypes = {
  upcomingAcademies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        info: PropTypes.shape({
          startDate: PropTypes.string.isRequired,
        }),
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    })
  ),
};

export { UpcomingAcademies };
