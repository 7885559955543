import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import classNames from 'classnames';

import './scss/video-player.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import IconPlayButton from 'icons/play-button.svg';
import { WISTIA_URL } from './constants';

const VideoPlayer = ({
  videoId,
  config,
  className,
  activeVideoTitle,
  hasVideoStarted,
  setVideoStarted,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const videoUrl = WISTIA_URL.concat(videoId);

  return (
    <div className={classNames('video-player', className && className)}>
      <div
        className={classNames('video-player__play-button', {
          hidden: hasVideoStarted,
        })}
      >
        <IconPlayButton className="video-player__play-icon" />
        <span className="sr-only">
          {translation['button.playVideo']}
          {activeVideoTitle && ` - ${activeVideoTitle}`}
        </span>
      </div>
      <ReactPlayer
        url={videoUrl}
        config={{
          wistia: {
            options: config,
          },
        }}
        onPlay={() => setVideoStarted(true)}
        className="video-player__video"
      />
    </div>
  );
};

VideoPlayer.propTypes = {
  hasVideoStarted: PropTypes.bool,
  setVideoStarted: PropTypes.func,
  videoId: PropTypes.string.isRequired,
  activeVideoTitle: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.oneOfType([PropTypes.shape()]),
};

export { VideoPlayer };
