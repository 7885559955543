import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { AcademyContactsRow } from './AcademyContactsRow';
import { AcademyTrademark } from './AcademyTrademark';

const AcademyContacts = ({ email, phone, className, trademark }) => {
  return (
    <div
      className={classNames('academy-contacts', className && `${className}`)}
    >
      <div className="page__frame">
        <AcademyContactsRow phone={phone} email={email} />
        <AcademyTrademark trademark={trademark} />
      </div>
    </div>
  );
};

AcademyContacts.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  className: PropTypes.string,
  trademark: PropTypes.string.isRequired,
};

export { AcademyContacts };
