import React from 'react';
import PropTypes from 'prop-types';

import './scss/testimonial.scss';
import { Image } from 'components/conditionalImage';

const Testimonial = ({ profileImage, testimonialText, name, title }) => (
  <div className="testimonial">
    <figure className="testimonial__figure">
      <Image
        image={profileImage}
        imageText={name}
        fixed
        className="testimonial__image"
      />
    </figure>
    <div className="testimonial__wrapper">
      <p className="testimonial__quote">{testimonialText}</p>
      <p className="testimonial__credits">
        <span className="testimonial__name">{name}</span>
        <span className="testimonial__title">{`, ${title}`}</span>
      </p>
    </div>
  </div>
);

Testimonial.propTypes = {
  profileImage: PropTypes.shape(),
  testimonialText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export { Testimonial };
