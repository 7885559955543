import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import './scss/images-block.scss';

const FeaturedImages = ({ images }) =>
  images &&
  images.length > 0 && (
    <div className="images-block">
      <div className="images-block__frame">
        <div className="images-block__scrollable-container">
          <div className="images-block__row">
            {images.map((image) => (
              <div
                className={classNames(
                  'images-block__column',
                  images.length === 2 && 'images-block__column--double'
                )}
                key={generateUniqueId()}
              >
                <figure className="images-block__tile">
                  <Img
                    className="images-block__image"
                    fluid={image.children[0].fluid}
                    alt=""
                  />
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

FeaturedImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape()),
};

export { FeaturedImages };
