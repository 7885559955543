import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import CircledArrow from 'icons/circled-arrow.svg';
import { getAcademyIcon } from 'utilities';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const Course = ({ upcomingAcademy }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <Link className="students-course" to={upcomingAcademy.fields.slug}>
      <div className="students-course__wrapper">
        <figure className="students-course__icon">
          <img
            src={getAcademyIcon(upcomingAcademy.frontmatter.info.type)}
            alt={`Icon - ${upcomingAcademy.frontmatter.title}`}
          />
        </figure>
        <div className="students-course__text">
          <h3
            className="students-course__title"
            dangerouslySetInnerHTML={{
              __html: upcomingAcademy.frontmatter.title.replace(
                'Academy for ',
                'Academy for <br/>'
              ),
            }}
          />
          <p className="students-course__description">
            {upcomingAcademy.frontmatter.shortDescription}
          </p>
        </div>
      </div>
      <span className="students-course__link btn-link btn-link--circled-arrow">
        {translation['button.learnMore']}
        <CircledArrow />
      </span>
    </Link>
  );
};

Course.propTypes = {
  upcomingAcademy: PropTypes.shape({
    frontmatter: PropTypes.shape({
      title: PropTypes.string.isRequired,
      shortDescription: PropTypes.string.isRequired,
      info: PropTypes.shape({
        type: PropTypes.string.isRequired,
      }),
    }),
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  }),
};

export { Course };
