import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { languageOptions } from 'lang';
import { Layout } from 'layout';
import {
  academyStudentsPagesLithuaniaEN,
  academyStudentsPagesPolandEN,
  academyStudentsPagesLithuanian,
  academyStudentsPagesPoland,
  contactInfo,
  getLocationsByCountry,
} from 'utilities';
import { MediaGallery } from 'components/mediaGallery';
import { MediaLinks } from 'components/mediaLinks';
import { HeroBannerInfo } from 'components/heroBanner';
import { SectionWithImage } from 'components/section';
import { TestimonialsSlider } from 'components/testimonialsSlider';
import { FeaturedImages } from 'components/featuredImages';
import {
  Courses,
  UpcomingAcademies,
  AcademyStudentsFooter,
} from './components';

const AcademyStudentsLanding = ({ data }) => {
  const { markdownRemark } = data;
  const hasLanguageDropdown = Boolean(markdownRemark.frontmatter.lang);

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;
  languageContext.setLanguage(
    languageOptions.find(
      (lang) => lang.id === markdownRemark.frontmatter.lang.toLowerCase()
    )
  );

  const contactsAndLocations = contactInfo()[0];
  const academyStudentsContacts = contactsAndLocations.frontmatter.contacts.find(
    (contact) => contact.academy === 'Students'
  );

  const getAcademies = () => {
    if (markdownRemark.frontmatter.country === 'LT')
      return languageContext.language.id === 'lt'
        ? academyStudentsPagesLithuanian()
        : academyStudentsPagesLithuaniaEN();
    if (markdownRemark.frontmatter.country === 'PL')
      return languageContext.language.id === 'pl'
        ? academyStudentsPagesPoland()
        : academyStudentsPagesPolandEN();

    return [];
  };
  const academies = getAcademies();
  const renderUpcomingAcademies = () => (
    <UpcomingAcademies upcomingAcademies={academies} />
  );
  const testimonialsList = markdownRemark.frontmatter.testimonials;
  const locationsArray = [
    ...getLocationsByCountry('Lithuania'),
    ...getLocationsByCountry('Poland'),
  ];

  return (
    <Layout
      darkTheme
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
      hasLanguageDropdown={hasLanguageDropdown}
    >
      <HeroBannerInfo
        backgroundImage={
          markdownRemark.frontmatter.heroBanner.heroBg &&
          markdownRemark.frontmatter.heroBanner.heroBg.children[0].fluid.src
        }
        title={markdownRemark.frontmatter.heroBanner.title}
        description={markdownRemark.frontmatter.heroBanner.shortDescription}
        renderRightSide={academies.length ? renderUpcomingAcademies : null}
        className="hero-banner--featured-elements students-landing__hero-banner"
      />
      <FeaturedImages images={markdownRemark.frontmatter.featuredImages} />
      <Courses
        title={markdownRemark.frontmatter.coursesTitle}
        upcomingAcademies={academies}
      />
      <SectionWithImage
        className="students-landing__section inverted"
        image={
          markdownRemark.frontmatter.about.aboutImage.image &&
          markdownRemark.frontmatter.about.aboutImage.image.children[0].fluid
        }
        imageText={markdownRemark.frontmatter.about.aboutImage.imageDescription}
        imageSide="right"
        title={markdownRemark.frontmatter.about.title}
        description={markdownRemark.frontmatter.about.description}
      />
      <TestimonialsSlider testimonialsArray={testimonialsList} />
      <MediaGallery
        title={translation['media.title']}
        videoId={markdownRemark.frontmatter.mediaGallery.videoId}
        images={markdownRemark.frontmatter.mediaGallery.images}
        className="inverted"
      />
      <MediaLinks
        links={markdownRemark.frontmatter.mediaLinks}
        className="inverted"
      />
      <AcademyStudentsFooter
        upcomingAcademies={academies}
        contact={academyStudentsContacts}
        locations={locationsArray.length > 0 && locationsArray}
        trademark={translation['contacts.trademark']}
      />
    </Layout>
  );
};

AcademyStudentsLanding.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const academyStudentsLandingQuery = graphql`
  query AcademyStudentsLanding($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        lang
        country
        about {
          title
          description
          aboutImage {
            image {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 799, maxHeight: 533) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageDescription
          }
        }
        testimonials
        heroBanner {
          title
          shortDescription
          heroBg {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 1700, maxHeight: 1100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        featuredImages {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 800, maxHeight: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        coursesTitle
        mediaGallery {
          images {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 800, maxHeight: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          videoId
        }
        mediaLinks {
          url
          name
          source
        }
        newsletterForm {
          title
          description
          optionsTitle
          options
          background {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 2000, maxHeight: 1500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AcademyStudentsLanding;
