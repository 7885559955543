import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import './scss/academy-contacts.scss';
import { AcademyContactsRow } from './AcademyContactsRow';
import { AcademyAddress } from './AcademyAddress';
import { AcademyTrademark } from './AcademyTrademark';

const AcademyContactsWithAddress = ({
  className,
  phone,
  email,
  locations,
  trademark,
}) => {
  return (
    <div
      className={classNames(
        'academy-contacts academy-contacts--wrapper academy-contacts--flex',
        className && `${className}`
      )}
    >
      <div className="academy-contacts__info">
        {(email || phone) && <AcademyContactsRow phone={phone} email={email} />}
        {locations.length &&
          locations.map((location) => (
            <AcademyAddress key={generateUniqueId()} location={location} />
          ))}
        <AcademyTrademark trademark={trademark} />
      </div>
    </div>
  );
};

AcademyContactsWithAddress.propTypes = {
  className: PropTypes.string,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      postalCode: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
    })
  ),
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  trademark: PropTypes.string.isRequired,
};

export { AcademyContactsWithAddress };
