import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './scss/section.scss';

const Section = ({ title, description, className }) => {
  return (
    <section className={classNames('section', className && `${className}`)}>
      <div className="section__content">
        <div className="section__text-wrapper">
          <h2 className="section__title">{title}</h2>
          <p className="section__description">{description}</p>
        </div>
      </div>
    </section>
  );
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { Section };
